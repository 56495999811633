<template>
  <div>
    <div class="echart-box" ref="box"></div>
  </div>
</template>

<script>
import { getYesterdaySp } from "@/api/pyApi";

export default {
  name: "lines",
  props: {
    lineDate: {
      type: Array,
      default: () => {
        return {}
      }
    },
    gpCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      yList: [],
      xList: [],
      min: 13,
      max: 14,
      name: '',
      kaiPan: [],
      now: "",
      colorZfe:""
    }
  },
  mounted() {
    for (let i = 0; i <= 240; i++) {
      this.xList.push(i)
    }
    this.getCa()

    setInterval(() => {
      if (this.isWorkTime()) {
        this.getData();
      }
    }, 2000)
  },
  methods: {
    showEcarts() {
      // 基于准备好的dom，初始化echarts实例
      const userdom = this.$refs.box
      const mycart = this.$echarts.init(userdom)
      const colorList = ["#9E87FF", '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF']
      const option = {
        backgroundColor: '#fff',
        title: {
          text: '',
          textStyle: {
            fontSize: 12,
            fontWeight: 400
          },
          left: 'center',
          top: '5%'
        },
        legend: {
          icon: 'circle',
          top: '5%',
          right: '5%',
          itemWidth: 6,
          itemGap: 20,
          textStyle: {
            color: '#556677'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
        },
        grid: {
          top: '15%'
        },
        xAxis: [{
          type: 'category',
          data: this.xList,
          axisLine: {
            lineStyle: {
              color: '#DCE2E8'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 29,
            textStyle: {
              color: '#556677'
            },
            // 默认x轴字体大小
            fontSize: 12,
            // margin:文字到x轴的距离
            margin: 15
          },
          axisPointer: {
            label: {
              // padding: [11, 5, 7],
              padding: [0, 0, 10, 0],
              margin: 15,
              // 移入时的字体大小
              fontSize: 12,
              backgroundColor: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: '#fff' // 0% 处的颜色
                }, {
                  // offset: 0.9,
                  offset: 0.86,
                  color: '#fff' // 0% 处的颜色
                }, {
                  offset: 0.86,
                  color: '#33c0cd' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#33c0cd' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            }
          },
          boundaryGap: false
        }],
        yAxis: [{
          min:this.min,
          max:this.max,
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#DCE2E8'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#556677'
            }
          },
          splitLine: {
            show: false
          }
        }],
        series: [{
          name: this.name,
          type: 'line',
          data: this.yList,
          symbolSize: 1,
          symbol: 'circle',
          smooth: true,
          yAxisIndex: 0,
          showSymbol: false,
          lineStyle: {
            width: 1,
            color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 0,
              color: '#9effff'
            },
              {
                offset: 1,
                color: '#9E87FF'
              }
            ]),
            shadowColor: 'rgba(158,135,255, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              color: colorList[0],
              borderColor: colorList[0]
            }
          }
        },
          {
            name: this.now,
            type: 'line',
            data: this.kaiPan,
            symbolSize: 1,
            symbol: 'circle',
            smooth: true,
            yAxisIndex: 0,
            showSymbol: false,
            lineStyle: {
              width: 1,
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                offset: 0,
                color: '#d9ceff'
              },
                {
                  offset: 1,
                  color: '#d9ceff'
                }
              ]),
              shadowColor: '#ffdef8',
              shadowBlur: 10,
              shadowOffsetY: 20
            },
            itemStyle: {
              normal: {
                color: this.colorZfe,
                borderColor: this.colorZfe
              }
            }
          }
        ]
      };
      // 渲染图表
      mycart.setOption(option)
    },
    getData() {
      console.log(this.lineDate,"this.lineDate")
      this.name = this.lineDate[0].股票名称
      const list = []
      this.lineDate.forEach(item => {
        list.push(item.收盘)
      })
      this.yList = list
      var min = 100;
      var max = 0;
      for (let i = 0; i < this.lineDate.length; i++) {
        if(this.lineDate[i].收盘 < min){
          min = this.lineDate[i].收盘
        }
        if(this.lineDate[i].收盘 > max){
          max = this.lineDate[i].收盘
        }
      }
      this.now = this.lineDate[this.lineDate.length - 1].收盘 + " " + ((this.lineDate[this.lineDate.length - 1].收盘 - this.kaiPan[0])/this.kaiPan[0]).toFixed(4) * 100 + "%"

      if(this.lineDate[this.lineDate.length - 1].收盘 > this.kaiPan[0]){
        this.colorZfe = '#ffc4f4'
      }else if (this.lineDate[this.lineDate.length - 1].收盘 < this.kaiPan[0]){
        this.colorZfe = '#81ffc5'
      }else {
        this.colorZfe = '#0053ff'
      }
      this.recursion(min)
      this.recursion(max)
      this.showEcarts();
    },
    getCa() {
      getYesterdaySp({"gpCode": this.gpCode}).then(res => {
        console.log(res.data.yesterday,'gpCode')
        var yesterday = res.data.yesterday
        for (let i = 0; i <= 240; i++) {
          this.kaiPan.push(yesterday)
        }
        this.min = yesterday - 0.1;
        this.max = yesterday + 0.1;
        this.getData();
      })
    },
    recursion(now){
      if(now < this.min){
        this.min = this.min - 0.1;
        this.max = this.max + 0.1;
        this.recursion(now)
      }
      if(now > this.max){
        this.min = this.min - 0.1;
        this.max = this.max + 0.1;
        this.recursion(now)
      }
    },
    isWorkTime (type) {
      const now = new Date()
      const dayOfWeek = now.getDay() // 0 (周日) 到 6 (周六)
      const hours = now.getHours()
      const minutes = now.getMinutes()
      // 转换成今天的分钟数，从00:00开始算起
      const currentMinutes = hours * 60 + minutes
      // 周一到周五的9:30到11:30转换为分钟
      const startWorkMinutes = 9 * 60 + 30 // 周一到周五的9:30
      const endWorkMinutes = 11 * 60 + 30 // 周一到周五的11:30
      const startWorkMinutes1 = 13 * 60// 周一到周五的13:00
      const endWorkMinutes1 = 15 * 60 // 周一到周五的15:00
      // 判断是否在周一到周五
      const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5
      // 判断是否在9:30到11:30之间
      if (type === 'qian') {
        const isInWorkHours = currentMinutes <= startWorkMinutes
        // 综合判断
        return isWeekday && isInWorkHours
      } else if (type === 'hou') {
        const isInWorkHours = currentMinutes >= endWorkMinutes1
        // 综合判断
        return isWeekday && isInWorkHours
      } else {
        const isInWorkHours = currentMinutes >= startWorkMinutes && currentMinutes <= endWorkMinutes
        const isInWorkHours1 = currentMinutes >= startWorkMinutes1 && currentMinutes <= endWorkMinutes1
        // 综合判断
        return isWeekday && (isInWorkHours || isInWorkHours1)
      }
    },

  }
}
</script>

<style scoped>
.echart-box{
  width: 500px;
  height: 350px;
  /*border: 3px solid pink;*/
  margin: 20px auto;
}

</style>
