import Axios from 'axios';


const axios = Axios.create({
  timeout: 30000,
  responseType: 'json'
});
// todo java所有请求加token
// axios.interceptors.request.use(config => {
//   const token = localStorage.getItem('token')
//   if (token && !config.headers.noAuth) {
//     config.headers['Authorization'] = 'Bearer ' + JSON.parse(token).value
//   }
//   config.headers['Cache-Control'] = 'no-cache';
//   return config;
// });

export { axios };
