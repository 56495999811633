import { axios } from '@/api/api-interceptor';

let base = '/java';


export const  getCategoryList = params => {
    return axios.post(`${base}/category/list`, params);
};

export const CategoryDelete = id => {
    return axios.post(`${base}/category/delete?id=${id}`);
};

export const addCategory = params => {
    return axios.post(`${base}/category/add`, params)
};

export const updateCategory = params => {
    return axios.post(`${base}/category/update`, params)
};

export const getSiteList = params => {
    return axios.post(`${base}/site/list`, params);
};

export const siteDelete = id => {
    return axios.post(`${base}/site/delete?id=${id}`);
};

export const addSite = params => {
    return axios.post(`${base}/site/add`, params)
};

export const updateSite = params => {
    return axios.post(`${base}/site/update`, params)
};

export const getCategorySiteList = params => {
    return axios.post(`${base}/list/categorySiteList`, params)
};

export const getTitles = params => {
    return axios.post(`${base}/list/titles`, params)
};

