<template>
  <div class="page-padding">
    <el-card>
      <template>
        <el-button type="primary" @click="dialogVisible = true, isAdd = true">新增</el-button>
        <el-table
            :data="list"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            border
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column sortable show-overflow-tooltip label="id" prop="id" min-width="40" />
          <el-table-column show-overflow-tooltip label="标题" prop="title" min-width="80" />
          <el-table-column show-overflow-tooltip label="图标" prop="icon" min-width="80" />
          <el-table-column sortable show-overflow-tooltip label="排序" prop="sort" min-width="80"/>
          <el-table-column fixed="right" label="操作" min-width="80">
            <template #default="scope">
              <el-button @click="edit(scope.row)" type="text">编辑</el-button>
              <el-button @click="del(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <ClassifyAdd :dialog=dialogVisible :isAdd=isAdd :form="form" @dialogClose="dialogClose"></ClassifyAdd>
      </template>
    </el-card>
  </div>
</template>

<script>

import {CategoryDelete, getCategoryList, getTitles} from "@/api/javaApi";
import ClassifyAdd from "@/views/admin/classifyAdd";

export default {
  name: "website",
  components: {
    ClassifyAdd
  },
  data () {
    return {
      list: [],
      dialogVisible: false,
      isAdd: false,
      form: {},
    }
  },
  async mounted () {
    this.getCategoryList(null)
  },
  methods: {
    async getCategoryList(title){
      const res = await getTitles();
      this.list = res.data
      for(let i = 0; i < this.list.length; i++){
        if(this.list[i].children){
          for (let j = 0; j < this.list[i].children.length; j++){
            this.list[i].children[j].title = this.list[i].children[j].name
            this.list[i].children[j].sort = this.list[i].children[j].num
            this.list[i].children[j].parentName = this.list[i].name
          }
        }
        this.list[i].parentName = '顶级'
        this.list[i].title = this.list[i].name
        this.list[i].sort = this.list[i].num

      }
      console.log(this.list, 'this.list')
    },
    async edit(item){
      this.form = item
      this.isAdd = false
      this.dialogVisible = true
    },
    async del(item){
      this.$confirm('确认删除？')
          .then(_ => {
            CategoryDelete(parseInt(item.id));
            setTimeout(() => {
              this.getCategoryList(null)
              this.$message.success('删除成功!')
            }, 500)
          })
          .catch(_ => {});
    },
    dialogClose(){
      this.dialogVisible = false
      setTimeout(() => {
        this.getCategoryList(null)
      }, 500)
    },
  }
}
</script>

<style scoped>

</style>
