<template>
  <div>
    <el-dialog
        :title="addOrEdit ? '新增' : '编辑'"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
        <el-form-item label="分类">
          <el-select v-model="formLabelAlign.categoryName" ref="selectRef" placeholder="请选择">
            <template slot="empty">
              <el-tree :data="data"
                       :props="defaultProps"
                       :default-expand-all=true
                       :expand-on-click-node=false
                       @node-click="handleNodeClick"></el-tree>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="formLabelAlign.title"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-upload
              action="#"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="handleSeatImageUpload"
              :file-list="fileList"
              :limit="2"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="formLabelAlign.description"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="formLabelAlign.url"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {updateSite, addSite} from "@/api/javaApi";
import {getTitleJson} from "@/api/pyApi";
import {uploading} from "@/api/fileApi";

export default {
  props: {
    dialog:{
      type: Boolean,
      default: false
    },
    isAdd:{
      type: Boolean,
      default: false
    },
    form:{
      type: Object,
      default: false
    },
  },
  watch:{
    dialog(val){
      this.dialogVisible = val
    },
    isAdd(val){
      if(val){
        this.formLabelAlign = {
          id: '',
          thumb: '',
          title: '',
          description: '',
          categoryId: '',
          categoryName: '',
          url: ''
        }
        this.fileList[0].url = null
      }
      this.addOrEdit = val
    },
    form(val){
      if(!this.addOrEdit){
        this.formLabelAlign = JSON.parse(JSON.stringify(val))
        this.fileList[0].url = this.formLabelAlign.thumb
      }
    },
  },
  data() {
    return {
      dialogVisible:false,
      addOrEdit:false,
      labelPosition: 'right',
      formLabelAlign: {
        id: '',
        thumb: '',
        title: '',
        description: '',
        categoryId: '',
        categoryName: '',
        url: ''
      },
      data: [{
        name: '顶级',
        id: 0,
        children: []
      }],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      fileList: [{url:null}]
    };
  },
  async mounted() {
    if (this.addOrEdit){
      this.formLabelAlign = {
        id: '',
        thumb: '',
        title: '',
        description: '',
        categoryId: '',
        categoryName: '',
        url: ''
      }
    }
    const res = await getTitleJson()
    this.data[0].children = res.data
  },
  methods: {
    //图片上传
    handleSeatImageUpload (file, fileList) {
      this.fileList = [{url:null}]
      const params = new FormData()
      params.append('image', file.file)
      params.append('token', 'f8c73c2d25794db6f14a1192ff9bc26e')
      uploading(params).then((resp) => {
        this.fileList[0].url = resp.data.thumb
        this.formLabelAlign.thumb = resp.data.thumb
      }).catch((e) => {
        console.log('上传失败')
        const uid = file.file.uid
        const idx = this.fileList.findIndex(item => item.uid === uid)
        this.fileList.splice(idx, 1)
        this.$message.error('上传失败!')
      })
    },
    //图片删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      console.log(file)
    },
    handleNodeClick(data) {
      if(!data.children){
        this.formLabelAlign.categoryId = data.id
        this.formLabelAlign.categoryName = data.name
        this.$refs.selectRef.blur()
      }
    },
    handleClose(done) {
      this.$emit('dialogClose');
    },
    add(){
      if(this.addOrEdit){
        addSite({
          id: this.formLabelAlign.id,
          thumb: this.formLabelAlign.thumb,
          title: this.formLabelAlign.title,
          description: this.formLabelAlign.description,
          categoryId: this.formLabelAlign.categoryId,
          url: this.formLabelAlign.url
        })
        this.formLabelAlign = {
          id: '',
          thumb: '',
          title: '',
          description: '',
          categoryId: '',
          url: ''
        }
        this.$message.success('添加成功!')
      }else {
        updateSite({
          ...this.formLabelAlign
        })
        this.$message.success('修改成功!')
      }
      this.$emit('dialogClose');
    }
  }
};
</script>

<style scoped>

</style>
