<template>
  <div class="page-padding">
    <el-card>
      <template>
        <el-button type="primary" @click="dialogVisible = true, isAdd = true">新增</el-button>
        <el-select style="margin: 10px;" v-model="categoryName" ref="selectRef" placeholder="请选择分类">
          <template slot="empty">
            <el-tree :data="data"
                     :props="defaultProps"
                     :default-expand-all=true
                     :expand-on-click-node=false
                     @node-click="handleNodeClick"></el-tree>
          </template>
        </el-select>
        <el-table :data="list" style="width: 100%" >
          <el-table-column show-overflow-tooltip label="分类" prop="categoryName" min-width="80" />
          <el-table-column show-overflow-tooltip label="标题" prop="title" min-width="80" />
          <el-table-column show-overflow-tooltip label="图标" prop="thumb" min-width="80">
            <template slot-scope="scope">
              <img :src="scope.row.thumb" style="width: 50px; height: 50px;" />
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="描述" prop="description" min-width="80"/>
          <el-table-column show-overflow-tooltip label="地址" prop="url" min-width="80"/>
          <el-table-column fixed="right" label="操作" min-width="80">
            <template #default="scope">
              <el-button @click="edit(scope.row)" type="text">编辑</el-button>
              <el-button @click="del(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <website-add :dialog="dialogVisible" :isAdd="isAdd" :form="form" @dialogClose="dialogClose"></website-add>
        <el-pagination style="margin-top:16px;" background :page-sizes="configPage.pageSizes"
                       :layout="configPage.layout" :current-page.sync="configPage.currentPage" :page-size.sync="configPage.pageSize"
                       :total="configPage.total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </template>
    </el-card>
  </div>
</template>

<script>

import {siteDelete, getSiteList, getTitles, getCategorySiteList} from "@/api/javaApi";
import WebsiteAdd from "@/views/admin/websiteAdd";
import { configPage } from '@/config/system'
import {getTitleJson} from "@/api/pyApi";

export default {
  name: "website",
  components: {
    WebsiteAdd
  },
  data () {
    return {
      list: [],
      dialogVisible: false,
      isAdd: false,
      form: {},
      configPage: {
        layout: configPage.pageLayout,
        currentPage: configPage.pageNo,
        pageSize: configPage.pageSize,
        pageSizes: configPage.pageSizes,
        total: 22
      },
      categoryId: [],
      categoryName: '',
      data: [{
        name: '顶级',
        id: 0,
        children: []
      }],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  async mounted () {
    this.getSiteList(this.categoryId)
    const res = await getTitleJson()
    this.data[0].children = res.data
  },
  methods: {
    //选择类型
    handleNodeClick(data) {
      this.configPage.currentPage = 1
      this.categoryId = []
      if(!data.children){
        this.categoryId.push(data.id)
        this.categoryName = data.name
      }else {
        this.categoryName = data.name
        this.forChildren(data, this.categoryId)
      }
      this.getSiteList(this.categoryId)
      this.$refs.selectRef.blur()
    },
    //循环获取pid，用查询
    forChildren(data, list) {
      for (let i = 0; i < data.children.length; i++){
        if(data.children[i].children){
          this.forChildren(data.children[i], list)
        }else {
          list.push(data.children[i].id)
        }
      }
    },
    //分页
    handleSizeChange() {
      const that = this
      that.getSiteList(this.categoryId)
    },
    //分页
    handleCurrentChange() {
      const that = this
      that.getSiteList(this.categoryId)
    },
    async getSiteList(categoryId){
      const res = await getSiteList({
        offset: this.configPage.currentPage,
        limit: this.configPage.pageSize,
        categoryId: categoryId
      });
      this.list = res.data.records
      this.configPage.total = parseInt(res.data.total)
      await getCategorySiteList();
    },
    async edit(item){
      this.form = item
      this.isAdd = false
      this.dialogVisible = true
    },
    async del(item){
      this.$confirm('确认删除？')
          .then(_ => {
            siteDelete(parseInt(item.id))
            setTimeout(() => {
              this.getSiteList(null)
              this.$message.success('删除成功!')
            }, 500)
          })
          .catch(_ => {});
    },
    dialogClose(){
      this.dialogVisible = false
      setTimeout(() => {
        this.getSiteList(this.categoryId)
      }, 500)
    },
  }
}
</script>

<style scoped>

</style>
