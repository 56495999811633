import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
// iconfont
import './assets/font/iconfont.css'
// axios
import axios from "axios"


// 挂载一个全局能使用
Vue.prototype.$http = axios
// 设置访问根路径, 请求初始信息
// axios.defaults.baseURL = "/api"
//引入echarts
import * as echarts from 'echarts'
//vue全局注入echarts
Vue.prototype.$echarts = echarts;

document.title = "smallWu"


Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
