
// 默认分页配置
export const configPage = {
  // 默认页码
  pageNo: 1,
  // 默认每页显示数量
  pageSize: 10,
  // 数量切换选项
  pageSizes: [10, 20, 50, 100],
  // 每页显示数量（少）
  pageSizeShort: 5,
  // 数量切换选项（少）
  pageSizesShort: [5, 10, 20, 50],
  // 分页显示内容
  pageLayout: 'total, prev, pager, next, sizes'
}

export const configMorePage = {
  // 默认页码
  pageNo: 1,
  // 默认每页显示数量
  pageSize: 100,
  // 数量切换选项
  pageSizes: [10, 20, 50, 100],
  // 每页显示数量（少）
  pageSizeShort: 5,
  // 数量切换选项（少）
  pageSizesShort: [5, 10, 20, 50],
  // 分页显示内容
  pageLayout: 'total, prev, pager, next, sizes'
}
