import axios from 'axios';

let base = '/py';

export const ca = params => {
    return axios.get(`${base}/ca`, {
        params: params
    });
};

export const ca_day = params => {
    return axios.get(`${base}/ca_day`, {
        params: params
    });
};

export const getLines = params => {
    return axios.get(`${base}/getLines`, {
        params: params
    });
};

export const addCode = params => {
    return axios.get(`${base}/addCode`, {
        params: params
    });
};

export const delCode = params => {
    return axios.get(`${base}/delCode`, {
        params: params
    });
};

export const getYesterdaySp = params => {
    return axios.get(`${base}/getYesterdaySp`, {
        params: params
    });
};

export const getMinMax = params => {
    return axios.get(`${base}/getMinMax`, {
        params: params
    });
};

export const minMax = params => {
    return axios.get(`${base}/minMax`, {
        params: params
    });
};

//更新正式页面（入参 string ，执行string命令）
export const strToSsh = params => {
    return axios.get(`${base}/ssh`, {
        params: params
    });
};

//更新测试页面
export const pullTest = params => {
    return axios.get(`${base}/pull`, {
        params: params
    });
};

//登录
export const login = params => {
    return axios.get(`${base}/login`, {
        params: params
    });
};

//登录
export const restartPalu = params => {
    return axios.get(`${base}/restartPalu`, {
        params: params
    });
};

//获取token
export const getCategoryAuth = params => {
    return axios.get(`/java/gunsApi/auth`, {
        params: params
    });
};

export const getTitleJson = params => {
    return axios.get(`/json/title.json`);
};

export const getListJson = params => {
    return axios.get(`/json/list.json`);
};
