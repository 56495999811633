import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import line from '@/components/Echarts/lineChart'


Vue.use(VueRouter)

const routes = [
  {path: '/', redirect: "/index"},
  {path: "/index", component: Index},
  {path: "/line", component: line},
  //  分类管理
  {
    path: "/classify",
    name: "classify",
    component: () => import("@/views/admin/classify.vue")
  },
  //  网站管理
  {
    path: "/website",
    name: "website",
    component: () => import("@/views/admin/website.vue")
  },
  // {path: "/admin", component: Home,
  //   children:[
  //     {path: "/changePwd", component: changePwd},
  //     {path: "/addCourse", component: addCourse},
  // ]}
]

const router = new VueRouter({
  routes
})

// 登录的时候不能跳转, 出现问题的时候使用
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(localhost, onResolve, onReject) {
  if(onResolve || onReject) return originalPush.call(this, localhost, onResolve, onReject)
  return originalPush.call(this, localhost).catch(err => err)
}

export default router
