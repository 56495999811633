import axios from 'axios';


export const uploading = params => {
    return axios.post(`http://8.137.70.74:9010/api/index.php`,params, {
        headers: {
            'Content-Type': 'multipart/form-data'}
    });

};
