<template>
  <div>
    <el-dialog
        :title="addOrEdit ? '新增' : '编辑'"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
        <el-form-item label="标题">
          <el-input v-model="formLabelAlign.title"></el-input>
        </el-form-item>
        <el-form-item label="父级">
          <el-select v-model="formLabelAlign.parentName" ref="selectRef" placeholder="请选择">
            <template slot="empty">
              <el-tree :data="data"
                       :props="defaultProps"
                       :default-expand-all=true
                       :expand-on-click-node=false
                       @node-click="handleNodeClick"></el-tree>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="图标">
          <el-input v-model="formLabelAlign.icon"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="formLabelAlign.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {addCategory,updateCategory} from "@/api/javaApi";
import {getTitleJson} from "@/api/pyApi";

export default {
  props: {
    dialog:{
      type: Boolean,
      default: false
    },
    isAdd:{
      type: Boolean,
      default: false
    },
    form:{
      type: Object,
      default: false
    },
  },
  watch:{
    dialog(val){
      this.dialogVisible = val
    },
    isAdd(val){
      if(val){
        this.formLabelAlign = {
          id: '',
          sort: '',
          title: '',
          icon: '',
          parentId: '',
          parentName: '',
        }
      }
      this.addOrEdit = val
    },
    form(val){
      if(!this.addOrEdit){
        this.formLabelAlign = JSON.parse(JSON.stringify(val))
      }
    },
  },
  data() {
    return {
      dialogVisible:false,
      addOrEdit:false,
      labelPosition: 'right',
      formLabelAlign: {
        id: '',
        sort: '',
        title: '',
        icon: '',
        parentId: '',
        parentName: '',
      },
      data: [{
        name: '顶级',
        id: 0,
        children: []
      }],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
  },
  async mounted() {
    if (this.addOrEdit){
      this.formLabelAlign = {
        id: '',
        sort: '',
        title: '',
        icon: '',
        parentId: ''
      }
    }
    const res = await getTitleJson()
    this.data[0].children = res.data
  },
  methods: {
    handleNodeClick(data) {
      console.log(data)
      this.formLabelAlign.parentId = data.id
      this.formLabelAlign.parentName = data.name
      console.log(this.formLabelAlign,'formLabelAlign')
      this.$refs.selectRef.blur()
    },
    handleClose(done) {
      this.$emit('dialogClose');
    },
    add(){
      if(this.addOrEdit){
        addCategory({
          id: this.formLabelAlign.id,
          sort: this.formLabelAlign.sort,
          title: this.formLabelAlign.title,
          icon: this.formLabelAlign.icon,
          parentId: this.formLabelAlign.parentId
        })
        this.formLabelAlign = {
          id: '',
          sort: '',
          title: '',
          icon: '',
          parentId: ''
        }
        this.$message.success('添加成功!')
      }else {
        updateCategory({
          ...this.formLabelAlign
        })
        this.$message.success('修改成功!')
      }
      this.$emit('dialogClose');
    },
  }
};
</script>

<style scoped>

</style>
